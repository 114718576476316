<template>
  <b-container fluid class="p-0">
    <b-row>
      <b-col>
        <b-card class="card">
          <template #header>
            <h6 class="mb-0">Cảm xúc bệnh nhân {{ title }} ngày qua</h6>
          </template>
          <b-overlay :show="emotionsLoading">
            <div style="width: 500px; height: 350px; margin: auto">
              <v-chart :option="donutOptions" autoresize />
            </div>
          </b-overlay>
        </b-card>
      </b-col>
      <b-col>
        <b-container fluid class="p-0">
          <b-row>
            <b-col>
              <b-card class="card">
                <template #header>
                  <h6 class="mb-0">Tổng quan triệu chứng</h6>
                </template>
                <b-overlay :show="symptomsLoading">
                  <div class="content">
                    <div
                      v-for="(item, index) in symptoms"
                      :key="index"
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <div class="">
                        <img
                          :src="item.icon"
                          onerror="javascript:this.src='/media/placeholder/placeholder-image.png'"
                          class="content__icon"
                        />
                        <span class="content__name">{{ item.name }}</span>
                      </div>
                      <span class="content__count">{{ item.count }}</span>
                    </div>
                  </div>
                </b-overlay>
              </b-card>
            </b-col>
            <b-col>
              <b-card class="card">
                <template #header>
                  <h6 class="mb-0">Tổng quan hoạt động</h6>
                </template>
                <b-overlay :show="activityLoading">
                  <div class="content">
                    <div
                      v-for="(item, index) in activities"
                      :key="index"
                      class="d-flex align-items-center justify-content-between mb-2"
                    >
                      <div>
                        <img
                          :src="item.icon"
                          onerror="javascript:this.src='/media/placeholder/placeholder-image.png'"
                          class="content__icon"
                        />
                        <span class="content__name">{{ item.name }}</span>
                      </div>
                      <span class="content__count">{{ item.count }}</span>
                    </div>
                  </div>
                </b-overlay>
              </b-card>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { PieChart } from 'echarts/charts';
import VChart from 'vue-echarts';
use([CanvasRenderer, PieChart]);
export default {
  name: 'FeelingTab',
  components: { VChart },
  props: {
    userId: {
      type: String,
    },
    selectDay: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      symptoms: [],
      activities: [],
      donutOptions: {
        height: 350,
        label: {
          rich: {
            b: {
              // backgroundColor: 'red',
            },
            c: {
              color: '#ff8811',
              textBorderColor: '#000',
              textBorderWidth: 1,
              fontSize: 22,
            },
          },
          formatter: ' {b|{b}} ({c}) ',
        },
        labelLine: {
          smooth: 0.2,
          length: 15,
          length2: 220,
        },
        legend: {
          show: false,
          orient: 'vertical',
          left: 'left',
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '60%'],
            data: [],

            emphasis: {
              itemStyle: {
                shadowBlur: 0,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      },

      searchParams: {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        periodFilterType: this.selectDay,
      },
      symptomsLoading: false,
      emotionsLoading: false,
      activityLoading: false,
      donutImage: [],
    };
  },
  computed: {
    patientId() {
      return this.userId;
    },
    title() {
      const days = {
        1: '7',
        2: '14',
        3: '30',
        4: '90',
      };
      return days[this.selectDay];
    },
  },
  watch: {
    selectDay: {
      handler(newVal) {
        this.searchParams.periodFilterType = newVal;
        this.loadData();
      },
    },
  },
  methods: {
    async loadSymtom() {
      this.symptomsLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Emotion/statistic/symptom', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        let symptoms = data.symptoms.map((e) => ({
          count: e.count,
          name: e.text,
          icon: e.icon?.url,
        }));
        this.symptoms = symptoms;
        this.symptomsLoading = false;
      } catch (error) {
        this.symptomsLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    async loadActivities() {
      this.activityLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Emotion/statistic/activity', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        let activities = data.activities.map((e) => ({
          count: e.count,
          name: e.text,
          icon: e.icon?.url,
        }));
        this.activities = activities;
        this.activityLoading = false;
      } catch (error) {
        this.activityLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    async loadEmotions() {
      this.emotionsLoading = true;
      try {
        let { data } = await this.$api.get('Admin/Emotion/statistic/emotion', {
          params: { ...this.searchParams, patientId: this.patientId },
        });
        let series = data.emotions.map((e) => ({
          value: e.count > 0 ? e.count : null,
          itemStyle: { color: e.colorCode },
          name: e.text,
          icon: e?.icon?.url,
        }));
        this.donutOptions.series[0].data = series;
        this.donutImage = series;
        this.emotionsLoading = false;
      } catch (error) {
        this.emotionsLoading = false;
        this.$toastr.e(error, 'ERROR');
      }
    },
    loadData() {
      this.loadSymtom();
      this.loadActivities();
      this.loadEmotions();
    },
  },
  created() {
    this.loadData();
  },
};
</script>

<style lang="scss" scoped>
.content {
  &__icon {
    width: 30px;
  }
  &__name {
    color: #333;
    font-weight: 500;
    margin-left: 10px;
  }
  &__count {
    color: #01645a;
    font-weight: 600;
  }
}
</style>
